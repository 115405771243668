export const triviaContents = [{
    primaryTitle : "Ciekawostki",
    primaryContents: <>
        <p>1. Wprowadzony na rynek w 2002 roku Airbus A340-600 (na obrazku), był w swoim czasie najdłuższym samolotem pasażerskim świata. Przy 76.36 m długości, był on ponad 4 metry dłuższy od ówczesnego rekordzisty Boeinga 747-400, oraz około 2.3 m dłuższy od największego samolotu świata, wprowadzonego w 2007 roku Airbusa A380. Zdetronizował go dopiero wprowadzony w 2012 roku Boeing 747-8I, największy i ostatni wariant słynnego "Jumbo Jeta". Imponująca długość Airbusa nie przełożyła się jednak na imponującą sprzedaż, wyprodukowano jedynie 97 egzemplaży.</p>
        <p>2. Średnio w każdym momencie w powietrzu znajduje się blisko 10 000 samolotów i około 1.27 miliona pasażerów.</p>
        <p>3. Blisko 60% deweloperów studia Asobo odpowiedzialnego za Microsoft Flight Simulator (2020), zrobiło w trakcie pracy nad grą licencję pilota</p>
        <p>4. Związek Radziecki posiadał własny ponaddźwiękowy samolot pasażerski - Tu-144, który nawet swój pierwszy lot odbył się kilka miesięcy przed Concorde'a. Powszechnie uznaje się, że Sowieci wykradli dokumenty projektowe Concorde'a, i połączyli je ze swoją myślą techniczną, aby na prędce zkleić ze sobą ponaddźwiękowego potworka. Tu-144, prawdopodobnie najbardziej zasłynął swoim występem na Międzynarodowym Salonie Lotniczym w Paryżu w 1973, kiedy to rozpadł się w powietrzu w trakcie lotu pokazowego. Samolot ostatecznie trafił do służby w 1977 roku, został jednak wycofany z użytku w lotach pasażerskich już 7 miesięcy później, ze względu na wysokie koszta eksloatacji, oraz koszmarną wręcz awaryjność. Pozostał jednak okazjonalnie wykorzystywany do szkolenia rosyjskich astronautów do 1999 roku. W przeciwieństwie do Concorde'a który słynął z bardzo dobrze wyciszonej kabiny, w kabinie Tu-144 było tak głośno, że nie dało się rozmawiać, pasażerowie musieli podawać sobie notatki aby się ze sobą porozumieć.</p>
        <p>5. W całej historii awiacji znany jest tylko jeden przypadek bezpiecznego lądowania samolotem po utracie wszystkich systemów hydraulicznych (co się równa kompletnej utracie kontroli nad powierzchniami sterującymi). W 2003 Airbus A300 wykonujący lot towarowy DHL Express 209 z Bagdadu do Bahrajnu, został trafiony rakietą przez irackich rebeliantów. Pomimo kompletnej utraty sterów, załodze udało się bezpiecznie wylądować spowrotem w Bagdadzie, kontrolując samolot jedynie poprzez manipulację ciągiem silników. Jedyny inny przypadek kiedy ktoś się w ogóle zbliżył do czegoś takiego, to lot United Airlines 232 z 1989 roku, który również stracił wszystkie 3 układy hydrauliczne, ten samolot jednak rozbił się na pasie startowym podczas próby lądowania (zginęło 112 osób, przeżyło 184 w tym piloci). </p>
        
        </>,
    secondaryTitle : "O tej stronie:",
    secondaryContents: <>
        <p>Tutaj znajdziesz najprzeróżniejsze ciekawostki związane z samolotami. Nie oczekuj że dowiesz się czegokolwiek użytecznego.</p>
        </>
}]