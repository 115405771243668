import './App.css';
import { galleryContents } from './galleryContents';

function AboutPage() {
    return (
        <div id="main">
            <div className="content">
                <div className="gallery">
                    <div className="column">
                        <div className="imgbox">
                            <img src="/Lufthansa_Airbus_A340-600.jpg" alt="Obrazek" />
                            <p className="etykieta">Airbus A340-600</p>
                        </div>
                        <div className="imgbox">
                            <img src="/737-vs-777.jpg" alt="Obrazek" />
                            <p className="etykieta">Boeing 737-300 (przód) i 777-300ER (tył)</p>
                        </div>
                        <div className="imgbox">
                            <img src="/A318.jpg" alt="Obrazek" />
                            <p className="etykieta">Airbus A318</p>
                        </div>
                    </div>
                    <div className="column">
                    <div className="imgbox">
                            <img src="/747-8.jpg" alt="Obrazek" />
                            <p className="etykieta">Boeing 747-8I</p>
                        </div>
                        <div className="imgbox">
                            <img src="/A380.jpg" alt="Obrazek" />
                            <p className="etykieta">Airbus A380-800</p>
                        </div>
                        <div className="imgbox">
                            <img src="/A220.jpg" alt="Obrazek" />
                            <p className="etykieta">Airbus A220-300</p>
                        </div>
                    </div>
                    <div className="column">
                    <div className="imgbox">
                            <img src="/E190.jpg" alt="Obrazek" />
                            <p className="etykieta">Embraer E195</p>
                        </div>
                        <div className="imgbox">
                            <img src="/757.jpg" alt="Obrazek" />
                            <p className="etykieta">Boeing 757</p>
                        </div>
                        <div className="imgbox">
                            <img src="/A350.jpg" alt="Obrazek" />
                            <p className="etykieta">Airbus A350-1000</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AboutPage;