export const aboutContents = [{
    primaryTitle : "Czym właściwie jest ta storna?",
    primaryContents: <>
        <p>Ta strona powstała w ramach zaliczenia z przedmiotu na uczelni. Nie miałem lepszego pomysłu, więc zrobiłem stronę o samolotach, bo trochę się tym kiedyś interesowałem, i bardzo łatwo jest zdobyć pozbawione praw autorskich zdjęcia. Jeżeli jakimś cudem natrafiłeś na tą stronę przypadkowo, proszę bardzo rozejrzyj się, ale nie oczekuj że dowiesz się tutaj czegoś użytecznego.</p>
        <p>Odnośnie samej strony, jest to single page application napisana w React-cie. Zaimplementowałem tutaj prosty system renderowania warunkowego, bo miało być single-page, i o ile z technicznego punktu widzenia Reactowy routing też jest formą SPA, to z jakiegoś powodu miałem wrażenie jakby to było trochę oszukiwanie, a więc jest tak bardzo jedna strona jak to możliwe. Co do reszty, to właściwie nie ma tu nic ciekawego, layout zrobiłem sam przy użyciu HTML i CSS, nie kożystałem z żadnych templatek, ani nic z tych rzeczy, układ jest prosty, ale chyba spełnia swoje zadanie, i moim skromnym zdaniem nawet nie wygląda kompletnie beznadziejne.</p>
        <p>Możliwe, że kiedyś wykorzystam tą domenę do jakiegoś portfolio czy czegoś w tym stylu, ale póki co są tu samoloty.</p>
        </>,
    secondaryTitle : "O autorze:",
    secondaryContents: <>
        <p>Jestem tylko bidnym studentem, próbującym zaliczyć przedmiot.</p>
        </>
}]