import { useState } from 'react';
import './navbar.css';

function Navbar({view, setView, lang, setLang}) {
    return(
        <nav>
            <h1>Krzysztof Rogulski</h1>
            <div className="menu">
                <a className="menu-item" onClick={() => setView("home")} style={{color: view == "home" && "#00fc13"}}>Strona Główna</a>
                <a className="menu-item" onClick={() => setView("about")} style={{color: view == "about" && "#00fc13"}}>Co ja tu robię?</a>
                <a className="menu-item" onClick={() => setView("trivia")} style={{color: view == "trivia" && "#00fc13"}}>Ciekawostki</a>
                <a className="menu-item" onClick={() => setView("gallery")} style={{color: view == "gallery" && "#00fc13"}}>Galeria</a>
                {lang == "pl" ? <a className="menu-item" onClick={() => setLang("en")}>English</a> : <a className="menu-item" onClick={() => setLang("pl")}>Polski</a>}
            </div>
        </nav>
    );
}

export default Navbar;