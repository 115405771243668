import { useState } from 'react';
import Navbar from './navbar';
import HomePage from './homePage'
import AboutPage from './aboutPage';
import TriviaPage from './triviaPage';
import GalleryPage from './galleryPage';

function App() {
  const [view, setView] = useState("home");
  const [lang, setLang] = useState("pl");
  return (
    <>
      <Navbar view = {view} 
        setView = {setView.bind(this)} 
        lang = {lang}
        setLang= {setLang.bind(this)}/>
      {view === "home" && <HomePage />}
      {view === "about" && <AboutPage />}
      {view == "trivia" && <TriviaPage />}
      {view == "gallery" && <GalleryPage />}
    </>
  );
}

export default App;