import './App.css';
import { homeContents } from './homeContents';

function HomePage() {
    return (
        <div id="home-main">
            <div className="content" id="home">
                {/* <img className="picture" src="./Lufthansa_Airbus_A340-600.jpg" alt="Bardzo długi samolot" /> */}
                <div className="primary">
                    <h2>{homeContents[0].primaryTitle}</h2>
                    {homeContents[0].primarySubtitle}
                </div>
            </div>
        </div>
    );
}
export default HomePage;