import './App.css';
import { aboutContents } from './aboutContents';

function AboutPage() {
    return (
        <div id="main">
            <div className="content">
                <div className="primary">
                    <h2>{aboutContents[0].primaryTitle}</h2>
                    <div className="imgbox">
                        <img src="/doge.jpg" alt="Obrazek psa" />
                        <p className="etykieta">A to jest pieseł :)</p>
                    </div>
                    {aboutContents[0].primaryContents}
                </div>
                <div className="secondary">
                    <div>
                        <h2>{aboutContents[0].secondaryTitle}</h2>
                        {aboutContents[0].secondaryContents}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AboutPage;