import "./App.css"
import { triviaContents } from "./triviaContents";

function TriviaPage() {
    {
        return (
            <div id="main">
                <div className="content">
                    <div className="primary">
                        <h2>{triviaContents[0].primaryTitle}</h2>
                        <div className="imgbox">
                            <img src="/Lufthansa_Airbus_A340-600.jpg" alt="Bardzo długi samolot" />
                            <p className="etykieta">Airbus A340-600</p>
                        </div>
                        {triviaContents[0].primaryContents}
                    </div>
                    <div className="secondary">
                        <div>
                            <h2>{triviaContents[0].secondaryTitle}</h2>
                            {triviaContents[0].secondaryContents}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TriviaPage;